<template>
  <div class="page-gray">
    <div class="studentItem" v-for="item in studentList " :key="item.id" @click="switchStudent(item)">
        <img src="./../../assets/images/student/edit.png"
             @click.stop="$router.push({path:`/student/edit/${item.id}`,query:{schoolId:item.schoolid}})" class="edit"
             alt="">
        <span class="del"  @click.stop="deleteStudent(item)">注销</span>
<!--        <img src="@/assets/images/goods/goodsDetail/skuClose.png"-->
<!--             @click.stop="deleteStudent(item)" class="del"-->
<!--           alt="">-->
        <div class="avater">
          <img :src="item.photo | Avatar" alt="">
          <img src="./../../assets/images/student/current.png" v-if="item.id == currentStudentId" class="current"
               alt="">
        </div>
        <div class="student">
          <div class="name">
            {{ item.receiverName }}
            <span> {{ item.phone }}</span>
          </div>

          <div class="bottom">
            <div class="item">
              <div class="value"> {{ item.school }}</div>
            </div>
            <!--          <div class="item">-->
            <!--            <div class="value">{{ item.grade }}</div>-->
            <!--          </div>-->
            <!--          <div class="item">-->
            <!--            <div class="value">{{ item.classn }}</div>-->
            <!--          </div>-->
          </div>
        </div>
    </div>

    <div class="addItem" @click="$router.push('/enterSchoolCode')">
      <img src="./../../assets/images/student/add.png" alt="">
      <p>添加学生</p>
    </div>
  </div>
</template>

<script>
import Vue from "vue";

export default {
  name: "newList",
  data() {
    return {
      currentStudentId: '1',
      studentList: []
    }
  },
  mounted() {
    let doNotJump = this.$route.query.doNotJump;
    this.init(doNotJump);

  },
  methods: {
    init(doNotJump){
      this.$http.get(`/mall/h5_mall/queryAllStudentByUserId`, {
        params: {
          userId: Vue.prototype.userId
        }
      }, {
        emulateJSON: true
      }).then(res => {
        if(doNotJump){
          this.studentList = res.data;
        }else if(res.data.length > 1){
          this.studentList = res.data;
        }else{
          this.$router.push({path: '/', query: {}})
        }
      })
    },
    switchStudent(item) {
      this.$dialog.confirm({
        message: '确定以该学生进入商城',
      }).then(() => {
        //添加默认学生
        this.$http.post(this.baseMallUrl + `/h5_mall/switchDefaultStudents/${item.id}`, {}, {emulateJSON: true}).then(() => {
          //操作成功 获取学校信息
          this.$router.push({path: '/', query: {}})
        })
      }).catch(() => {
      });
    },
    deleteStudent(item){
      this.$dialog.confirm({
        message: '确认删除该学生信息',
      }).then(() => {
        //添加默认学生
        this.$http.post(this.baseMallUrl + `/h5_mall/delStudent/${item.id}`, {}, {emulateJSON: true}).then(() => {
          //操作成功 获取学校信息
          this.init();
        })
      }).catch(() => {
      });
    }
  },
}

</script>
<style lang="less" scoped>
.studentItem {
  display: flex;
  padding: 20px 20px 15px 20px;

  background: #FFFFFF;
  box-shadow: 0px 5px 12px 0px rgba(217, 226, 233, 0.5);
  border-radius: 8px;
  margin: 10px;
  position: relative;

  .edit {
    position: absolute;
    top: 14px;
    right: 19px;
    height: 12px;
    width: 12px;
  }

  .del {
    position: absolute;
    top: 1px;
    left: 5px;
    width: 50px;
    font-size: 12px;
    color: #828282;
  }

  .avater {
    min-width: 57px;
    width: 57px;
    height: 57px;
    position: relative;
    border-radius: 50%;

    img {
      height: 100%;
      width: 100%;
      border-radius: 50%;
    }

    .current {
      height: 12px;
      width: 12px;
      position: absolute;
      bottom: 0;
      right: 6px;
    }
  }

  .student {
    flex: 1;
    width: 0;
    margin-left: 13px;

    .name {
      font-size: 18px;
      color: #212121;
      margin: 7px 0 10px 0;
      line-height: 1;

      span {
        font-size: 12px;
        color: #07C160;
        margin-left: 13px;
      }
    }

    .address {
      line-height: 12px;
      margin-bottom: 20px;

      span {
        display: inline-block;
        width: 5px;
        height: 5px;
        background: #CCCCCC;
        border-radius: 50%;
        margin-right: 6px;
        vertical-align: middle;
      }

      font-size: 12px;
      color: #212121;
    }

    .bottom {
      .item {
        display: inline-block;
        margin-right: 35px;

        .key {
          line-height: 11px;
          font-size: 11px;
          color: #C6C6C6;
        }

        .value {
          line-height: 12px;
          font-size: 12px;
          color: #212121;
          margin-top: 10px;
        }
      }
    }
  }
}

.addItem {
  height: 134px;
  background: #FFFFFF;
  box-shadow: 0px 5px 12px 0px rgba(217, 226, 233, 0.5);
  border-radius: 8px;
  margin: 10px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    height: 35px;
    width: 35px;
    margin-bottom: 17px;
  }

  p {
    font-size: 12px;
    color: #212121;
    line-height: 1;
    text-align: center;
  }
}
</style>
